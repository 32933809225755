import React from 'react'
import { ISectionProps, ISectionText } from '../../../../interfaces/pages/portfolio/ISection'

const TextSection = (props: ISectionProps) => {
    const section = props.section as ISectionText
    return (
        <p>
            { section.text[props.lang] }
        </p>
    )
}

export default TextSection