import IPage from '../interfaces/IPage'
import React from 'react'
import INav from '../interfaces/INav'
import routeTo from '../util/route'

const Nav = (props: { nav: INav, pages: IPage[], setNav: (prevState: any) => void }) => {

    const navigateToPage = (page: IPage) => {
        let route = props.nav.subPage[page.systemName] ?
            `${ page.systemName }/${ props.nav.subPage[page.systemName] }` :
            page.systemName
        console.log('navigating to page' , page, props.nav)
        routeTo(route)
    }

    return (
        <nav>
            <section>
                {
                    props.pages.map((page: IPage) => {
                        return (
                            <button
                                key={ page.systemName }
                                className={ props.nav.page === page.systemName ? 'active' : '' }
                                onClick={ () => { navigateToPage(page) } }
                            >
                                { page.systemName }
                            </button>
                        )
                    })
                }
            </section>
            <section>
                {
                    ['en', 'nl'].map((lang: string) => {
                        return (
                            <button
                                key={ lang }
                                className={ props.nav.lang === lang ? 'active' : '' }
                                onClick={ () => {
                                    localStorage.setItem('lang', lang);
                                    props.setNav((prevState: INav) => ({ ...prevState, lang: lang }))
                                } }
                            >
                                { lang.toUpperCase() }
                            </button>
                        )
                    })
                }
            </section>
        </nav>
    )
}

export default Nav
