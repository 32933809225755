import React from 'react'
import { ISectionProps, ISectionCitation } from '../../../../interfaces/pages/portfolio/ISection'

const CitationSection = (props: ISectionProps) => {
    const section = props.section as ISectionCitation
    return (
        <React.Fragment>
            <blockquote>
                { section.text[props.lang] }
            </blockquote>
            <cite>
                { section.source.author }
                { section.source.book && '. ' }
                { section.source.book && <i>{ section.source.book }</i> }
                { section.source.publisher && ', ' + section.source.publisher }
                { section.source.year && ', ' + section.source.year }
            </cite>
        </React.Fragment>
    )
}

export default CitationSection