import React, {FunctionComponent} from "react";
import { ISectionProps } from "../../../../interfaces/pages/portfolio/ISection";

import HeaderSection from './Header'
import TextSection from './Text'
import ImageSection from './Images'
import CitationSection from './Citation'

interface IComponents {
    [key: string]: FunctionComponent<ISectionProps>
}

const components: IComponents = {
    header: HeaderSection,
    text: TextSection,
    images: ImageSection,
    citation: CitationSection
}

const SectionFactory = (sectionProps: ISectionProps, key: number) => {
    if (sectionProps.section.type in components) {
            sectionProps.section.ref = React.createRef<HTMLElement>()

            return (
            <section
                ref={ sectionProps.section.ref }
                key={ key }
                className={ `work-section ${ sectionProps.section.type }`}
            >
                {
                    React.createElement(components[sectionProps.section.type], sectionProps)
                }
            </section>
        )
    }
}

export default SectionFactory