import React from 'react'
import IPage from '../interfaces/IPage'
import INav from '../interfaces/INav'
import dataRequest from '../util/request'

const Page = (props: { nav: INav, page: IPage, lang: INav['lang'] }) => {
    let [data, setData] = React.useState<Object | Object[] | undefined>(),
        pageRef = React.createRef<HTMLElement>()
    React.useEffect(() => {
        props.page.systemName === props.nav.page &&
        dataRequest(`/data/${props.nav.page}.json`).then((res: Object | Object[] | undefined) => {
            setData(res)
        })
    }, [props.page.systemName, props.nav.page])

    return (
        <section
            key={ `${ props.page.systemName }` }
            className={`page page-${ props.page.systemName }`}
            ref={ pageRef }
        >
            {
                data &&
                React.createElement(props.page.component, {
                    data: data || [],
                    nav: props.nav,
                    pageRef: pageRef
                })
            }
        </section>
    )
}

export default Page
