import React from 'react'
import { IWorkProps } from '../../../interfaces/pages/IPortfolio'
import SectionFactory from './sections/SectionFactory'
import i18n from '../../../i18n'
import { ISection } from '../../../interfaces/pages/portfolio/ISection'
import Image from '../../shared/Image'
import debounce from '../../../util/debounce'
import routeTo from '../../../util/route'

const Work = (props: IWorkProps)  => {
    let workRef = React.createRef<HTMLElement>(),
        [initialHeight, setInitialHeight] = React.useState(''),
        [domLoaded, setDomLoaded] = React.useState(false),
        [parentHeight, setParentHeight] = React.useState(''),
        [activePositions, setActivePositions] = React.useState({
            left: '',
            top: '',
            width: '',
            height: '',
        })

    let debouncedDomLoaded = debounce(domLoaded, 50)

    const setPositions = React.useCallback(() => {
        if (debouncedDomLoaded && !initialHeight && workRef.current && props.pageRef.current) {
            let computedParent = getComputedStyle(props.pageRef.current),
                padding = parseInt(computedParent.padding),
                height = getWorkHeight(workRef.current.children),
                parentHeight = props.pageRef.current.offsetHeight

            setParentHeight(`${ height > parentHeight ? height + padding * 2 : parentHeight }px`)
            setInitialHeight(`${ parentHeight }px`)
            setActivePositions({
                left: `-${ workRef.current.offsetLeft - padding }px`,
                top: `-${ workRef.current.offsetTop - padding }px`,
                width: `${ parseInt(computedParent.width) - padding * 2 }px`,
                height: `${ height > parentHeight ? height : parentHeight }px`,
            })
            setDomLoaded(false)
        }
    }, [debouncedDomLoaded, initialHeight, props.pageRef, workRef])

    React.useEffect(() => {
        setPositions()
    }, [setPositions])

    React.useEffect(() => {
        if (
            initialHeight &&
            parentHeight &&
            props.pageRef.current
        ) {
            if (window.location.pathname === '/portfolio') {
                props.pageRef.current.style.height = initialHeight
            }
            if (props.active) {
                props.pageRef.current.style.height = parentHeight
            }
        }
    }, [initialHeight, parentHeight, props.active, props.pageRef])

    const getWorkHeight = (children: HTMLCollection) => {
        let height = 0
        for (let i = 0; i < children.length; i++) {
            height += (children[i] as HTMLElement).offsetHeight
        }
        return height
    }

    return (
        <article
            className={ `work${ props.active ? ' active' : '' }${ props.work.highlighted ? ' highlighted' : '' }` }
            onClick={ () => !props.active ? routeTo(`portfolio/${ props.work.systemName }`) : undefined }
            onLoad={ () => setDomLoaded(true) }
        >
            <section
                ref={ workRef }
                className="work-content"
                style={ props.active ? activePositions : {} }
            >
                <header>
                    <section className="work-header visual" style={{ backgroundImage: `url('${ window.location.origin }/img/${ props.work.headerBackground }'` }}>
                        <div className="visual-logo">
                            <Image src={ props.work.logo } />
                        </div>
                    </section>
                    <h1>
                        { props.work.title[props.lang] }
                        <button className="close" onClick={ () => routeTo('portfolio')} />
                    </h1>
                </header>
                {
                    props.work.meta &&
                    <section className="work-section work-meta">
                        <table>
                            <tbody>
                                <tr>
                                    <td>{ i18n[props.lang].client }</td>
                                    <td>{ props.work.meta.client }</td>
                                </tr>
                                <tr>
                                    <td>{ i18n[props.lang].when }</td>
                                    <td>{ props.work.meta.date }</td>
                                </tr>
                                <tr>
                                    <td>{ i18n[props.lang].work }</td>
                                    <td>{ props.work.meta.type.join(', ') }</td>
                                </tr>
                            </tbody>
                        </table>
                        <section className="meta-images">
                            {
                                props.work.meta.images.map(imgSrc => {
                                    return (
                                        <Image
                                            key={ imgSrc }
                                            src={ imgSrc }
                                        />
                                    )
                                })
                            }
                        </section>
                    </section>
                }
                {
                    props.work.sections.map((section: ISection, index: number) => {
                        return (
                            SectionFactory({
                                section,
                                lang: props.lang
                            }, index)
                        )
                    })
                }
                <section className="work-buttons">
                    <button className="up" onClick={ () => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                    <button className="close" onClick={ () => routeTo('portfolio')} />
                </section>
            </section>
        </article>
    )
}

export default Work
