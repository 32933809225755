import React, { SyntheticEvent } from 'react'
import { ISectionImages, ISectionProps } from '../../../../interfaces/pages/portfolio/ISection'
import Image from '../../../shared/Image'

const ImageSection = (props: ISectionProps) => {
    const section = props.section as ISectionImages

    let [ref, setRef] = React.useState(props.section.ref ? props.section.ref.current : undefined),
        [activeImageIndex, setActiveImageIndex] = React.useState(0),
        [images, setImages] = React.useState<HTMLElement[]>([])

    const unsetActiveIndex = React.useCallback(() => {
        if (!ref) return
        let scrollPosition = ref.scrollLeft + ref.offsetWidth / 2
        images.forEach((image, index) => {
            if (scrollPosition >= image.offsetLeft && scrollPosition < image.offsetLeft + image.offsetWidth) {
                setActiveImageIndex(index)
            }
        })
    }, [images, ref])

    const scrollToActive = React.useCallback((index: number) => {
        let scrollTo = images[index].offsetLeft - ((ref?.offsetWidth || 0) / 2) + images[index].offsetWidth / 2
        ref?.scrollTo({ left: scrollTo, behavior: 'smooth' })
    }, [images, ref]);

    React.useEffect(() => {
        setRef(props.section.ref ? props.section.ref.current : undefined)
        if (ref && ref.offsetWidth < ref.scrollWidth) {
            ref.className += ref.className.includes('draggable') ? '' : ' draggable'
            ref.addEventListener('scroll', unsetActiveIndex)
        }
        return () => {
            ref?.removeEventListener('scroll', unsetActiveIndex)
        }
    }, [props.section.ref, ref, unsetActiveIndex])

    const onImageLoad = (evt: React.SyntheticEvent<HTMLElement>) => {
        setImages([
            ...images,
            evt.currentTarget
        ].sort((a, b) => a.offsetLeft - b.offsetLeft))
    }

    return (
        <React.Fragment>
            {
                section.images.map((src: string, index) => {
                    return (
                        <Image
                            key={index}
                            src={src}
                            clickHandler={ (evt) => {
                                evt.stopPropagation()
                                scrollToActive(index)
                            } }
                            loadHandler={ onImageLoad }
                            className={ activeImageIndex === index ? 'active' : ''}
                        />
                    )
                })
            }
        </React.Fragment>
    )
}

export default ImageSection