import React from 'react'
import { IWorkObject } from '../../interfaces/pages/IPortfolio'
import Work from './portfolio/Work'
import INav from '../../interfaces/INav'

const Portfolio = (props: { nav: INav, data: IWorkObject[], pageRef: React.RefObject<HTMLElement> }) => {
    let [active, setActive] = React.useState<IWorkObject | undefined>(undefined)

    React.useEffect(() => {
        let work = props.data.find((work: IWorkObject) => {
            return work.systemName === props.nav.subPage.portfolio
        })
        props.nav.page === 'portfolio' && setActive(work)
    }, [props.nav.subPage.portfolio, props.data])

    return (
        <React.Fragment>
            {
                props.data.map((work: IWorkObject, index: number) => {
                    return (
                        <Work
                            key={ `${ work.systemName }-${ index }` }
                            work={ work }
                            active={ !!active && active.systemName === work.systemName }
                            lang={ props.nav.lang }
                            pageRef={ props.pageRef }
                        />
                    )
                })
            }
        </React.Fragment>
    )
}

export default Portfolio
