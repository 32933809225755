import React from 'react'

const Image = (props: {
    src: string,
    className?: string,
    mouseDownHandler?: React.MouseEventHandler,
    clickHandler?: React.MouseEventHandler,
    loadHandler?: React.EventHandler<React.SyntheticEvent>
}) => {
    const imageDescription = props.src.split('.')[0].replace(/[-/]/g, ' ')
    return (
        <img
            className={props.className}
            src={props.src.startsWith('http') ? props.src : `${ window.location.origin }/img/${props.src}`}
            alt={imageDescription}
            title={imageDescription}
            onClick={props.clickHandler}
            onLoad={ props.loadHandler }
            draggable={false}
        />
    )
}

export default Image