const i18n = {
    en: {
        client: 'client',
        when: 'when',
        work: 'work',
        termsAndConditions: 'Terms and conditions',
    },
    nl: {
        client: 'klant',
        when: 'wanneer',
        work: 'werk',
        termsAndConditions: 'Algemene voorwaarden',
    }
}

export default i18n