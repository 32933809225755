import React from 'react'
import { IChapter } from '../../interfaces/pages/IHome'
import TypeWriter from '../shared/TypeWriter'
import INav from '../../interfaces/INav'
import Image from '../shared/Image'
import routeTo from '../../util/route'

const Home = (props: { data: IChapter[], nav: INav }) => {
    let [activeChapter, setActiveChapter] = React.useState<IChapter | undefined>(props.data[1])

    React.useEffect(() => {
        let newChapter = props.data.find((chapter: IChapter) => {
            return chapter.systemName === props.nav.subPage.home
        })
        newChapter && setActiveChapter(newChapter)
    }, [props.nav.subPage.home, props.data])

    const isActiveChapter = (chapter: IChapter, index: number) => {
        if (activeChapter && activeChapter.systemName === chapter.systemName) {
            return ' active'
        } else if (!activeChapter && index === 0) {
            return ' active'
        }
        return ''
    }

    const navigateToChapter = (chapter: IChapter) => {
        routeTo(`home/${ chapter.systemName }`)
    }

    return (
        <React.Fragment>
            {
                props.data.map((chapter: IChapter, index: number) => {
                    return (
                        <article
                            key={ chapter.systemName }
                            style={{ backgroundColor: chapter.color }}
                            className={ `chapter${ isActiveChapter(chapter, index) }`}
                        >
                            <h3
                                className="header"
                                style={{ backgroundColor: chapter.color }}
                                onClick={ () => navigateToChapter(chapter) }
                            >
                                { chapter.title[props.nav.lang] }
                            </h3>
                            <section className="content" style={{ backgroundImage: `url('${ window.location.origin }/img/${chapter.backgroundImage}')` }}>
                                {
                                    chapter.text && 
                                    <section className={ `text ${ chapter.mainFocus !== 'images' ? 'main' : '' }` }>
                                        {
                                            chapter.text.typeWriter &&
                                            <h4>
                                                { chapter.text.typeWriter[props.nav.lang].prefix + ' ' }
                                                { <TypeWriter values={ chapter.text.typeWriter[props.nav.lang].values }/> }
                                            </h4>
                                        }
                                        <p>{ chapter.text.body[props.nav.lang] }</p>
                                    </section>
                                }
                                {
                                    chapter.images &&
                                    <section className={ `images ${ chapter.mainFocus === 'images' ? 'main' : '' }` }>
                                        {
                                            chapter.images.map((image: { source: string, size: string, background: string }) => {
                                                return (
                                                    <Image
                                                        key={ image.source }
                                                        src={ image.source }
                                                        className={ `${ image.size } ${ image.background }` }
                                                    />
                                                 )
                                            })
                                        }
                                    </section>
                                }
                            </section>
                        </article>
                    )
                })
            }
        </React.Fragment>
    )
}

export default Home
