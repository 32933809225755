import React from 'react'
import { ISectionProps, ISectionText } from '../../../../interfaces/pages/portfolio/ISection'

const Header = (props: ISectionProps) => {
    const section = props.section as ISectionText
    return (
        <h2>
            { section.text[props.lang] }
        </h2>
    )
}

export default Header