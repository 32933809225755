import React from 'react'
import './App.scss'
import INav from './interfaces/INav'
import IPage from './interfaces/IPage'
import Header from './components/Header'
import Nav from './components/Nav'
import Page from './components/Page'
import Home from './components/pages/Home'
import Portfolio from './components/pages/Portfolio'
import Contact from './components/pages/Contact'
import debounce from './util/debounce'
import i18n from "./i18n";

function App() {
    let [domDone, setDomDone] = React.useState(false)
    let debouncedDomDone = debounce(domDone, 500)

    const resolveLanguage = () => {
        const availableLanguages = ['en', 'nl']
        return availableLanguages.find(lang => localStorage.getItem('lang') === lang) ||
            availableLanguages.find(lang => navigator.language.substring(0, 2) === lang) ||
            availableLanguages[0]
    }

    let [nav, setNav] = React.useState<INav>({
        page: undefined,
        subPage: {
            home: undefined,
            portfolio: undefined,
            contact: undefined
        },
        lang: resolveLanguage() as 'nl' | 'en'
    })

    const pages: IPage[] = React.useMemo(() => {
        return [
            {
                systemName: 'home',
                component: Home
            },
            {
                systemName: 'portfolio',
                component: Portfolio
            },
            {
                systemName: 'contact',
                component: Contact
            }
        ]
    }, [])

    const onRoute = React.useCallback(() => {
        setNav((prevState: INav) => {
            let pathArray = window.location.pathname.slice(1).split('/'),
                validPage = pages.find(page => page.systemName === pathArray[0])
            let route = {
                page: validPage ? validPage.systemName : pages[0].systemName,
                subPage: prevState.subPage
            }
            if (validPage) {
                route.subPage[route.page] = window.location.pathname.slice(1).split('/')[1]
            }
            return { ...prevState, ...route}
        })
    }, [pages])

    React.useEffect(() => {
        onRoute()
        window.onpopstate = (evt) => {
            evt.preventDefault()
            onRoute()
        }
    }, [onRoute, setNav])

    return (
        <React.Fragment>
            <Header />
            <Nav
                pages={ pages }
                nav={ nav }
                setNav={ setNav }
            />
            <section className="page-background" />
            <section
                onLoad={ () => setDomDone(true) }
                className={ `page-container ${ nav.page }${ debouncedDomDone ? ' animated' : '' }` }
            >
                {
                    pages.map((page: IPage) => {
                        return (
                            <Page
                                key={ page.systemName }
                                nav={ nav }
                                page={ page }
                                lang={ nav.lang }
                            />
                        )
                    })
                }
            </section>
            <footer>
                <a href="https://storage.googleapis.com/casperjonker.nl/files/AV-casperjonker-nl.pdf">{ i18n[nav.lang].termsAndConditions }</a><span>Copyright © casperjonker.nl 2023</span>
            </footer>
        </React.Fragment>
    )
}

export default App
