const dataRequest = (endpoint: string) => {
    return new Promise<any>((resolve, reject) => {
        fetch(endpoint).then(res => {
            if (!res.ok) {
                reject()
            }
            return res.json()
        }).then((res: string) => {
            resolve(res)
        })
    })
}

export default dataRequest
