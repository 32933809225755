import React from 'react'
import ITypeWriter from '../../interfaces/ITypeWriter'

const TypeWriter = (props: { values: string[] }) => {
    let [typeWriter, setTypeWriter] = React.useState<ITypeWriter>({
            value: '',
            index: 0,
            reverse: false
        })

    const iterateTypeWriter = () => {
        let delay: number = 50

        setTypeWriter((prevState: ITypeWriter) => {
            let word = props.values[prevState.index],
                reverse = prevState.reverse

            if (!reverse && word.length === prevState.value.length) {
                delay = 1000
                reverse = true
            } else if (reverse && prevState.value.length === 0) {
                delay = 400
                reverse = false
                prevState.index = prevState.index === props.values.length - 1 ? 0 : prevState.index + 1
            }

            return {
                value: word.substring(0, prevState.reverse ? prevState.value.length - 1 : prevState.value.length + 1),
                index: prevState.index,
                reverse: reverse
            }
        })

        setTimeout(iterateTypeWriter, delay)
    }

    React.useState(iterateTypeWriter)

    return (
        <span>{ typeWriter.value }</span>
    )
}

export default TypeWriter
