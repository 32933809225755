import { IContact, ITableObject, ISocialObject } from '../../interfaces/pages/IContact'
import INav from '../../interfaces/INav'
import Image from '../shared/Image'

const Contact = (props: { data: IContact, nav: INav }) => {
    return (
        <article
            style={{ backgroundImage: `url('${ window.location.origin }/img/${ props.data.backgroundImage }')` }}
        >
            <section className="text">
                <p>{ props.data.text[props.nav.lang] }</p>
            </section>
            <section className="info">
                <table>
                    <tbody>
                        {
                            props.data.table.map((info: ITableObject) => {
                                return (
                                    <tr key={ info.key[props.nav.lang] }>
                                        <td>{ info.key[props.nav.lang] }</td>
                                        <td>
                                            {
                                                info.url ?
                                                    <a href={ info.url }>{ info.value[props.nav.lang] }</a> :
                                                    info.value[props.nav.lang]
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <section className="socials">
                    {
                        props.data.socials.map((social: ISocialObject) => {
                            return (
                                <a key={ social.name }
                                   href={ social.url }
                                   target="_blank"
                                   rel="noreferrer">
                                    <Image src={ social.logo } />
                                </a>
                            )
                        })
                    }
                </section>
            </section>
        </article>
    )
}

export default Contact
